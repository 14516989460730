import React, { HTMLAttributes } from 'react';
import { Path, PathValue } from 'react-hook-form';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ControllerHOCProps, SelectionOption } from '../../types/interfaces';

import "./DynamicOptionSelection.scss";

const dynamicOptionSelectionDefault: SelectionOption[] = [
  {
    value: 'a',
    label: 'Option A',
  },
  {
    value: 'b',
    label: 'Option B',
  },
  {
    value: 'c',
    label: 'Option C',
  },
];

interface DynamicOptionSelectionProps<T, TSelected>
  extends HTMLAttributes<HTMLInputElement> {
  options: SelectionOption[];
  isMulti: boolean;
  value?: TSelected[];
  handleChange?: (value: TSelected[]) => void;
  disabled?: boolean;
  controller?: ControllerHOCProps<T>;
}

function DynamicOptionSelection<T, TSelected>({
  options,
  isMulti,
  value,
  handleChange,
  disabled,
  controller,
}: DynamicOptionSelectionProps<T, TSelected>) {
  const handleValueChange = (
    _event: React.MouseEvent<HTMLElement>,
    newValue: TSelected[]
  ) => {
    if (newValue === null && !isMulti) return;
    if (controller && controller?.setValue) {
      controller?.setValue(controller?.name, newValue as PathValue<T, Path<T>>);
    } else {
      if (handleChange) handleChange(newValue);
    }
  };

  return (
    <div className="DynamicOptionSelection">
      <ToggleButtonGroup
        value={value}
        {...controller?.field}
        size="small"
        exclusive={!isMulti}
        onChange={handleValueChange}
      >
        {options.map((option, i) => (
          <ToggleButton value={option.value} key={i} disabled={disabled}>
            <span className="selectionContent">{option.label}</span>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
}

export {
  DynamicOptionSelection,
  DynamicOptionSelectionProps,
  dynamicOptionSelectionDefault,
};
